import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	heading: {
		margin: `${theme.spacing.tiny} 0`,
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing.xxsmall,
	},
}));
