import { FC } from 'react';

import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/base/Button';
import { Icon, IconTypes } from '@vakantiesnl/components/src/atoms/Icon';

import { useStyles } from './CarouselControl.style';

export type CarouselControlProps = MUIButtonProps & {
	size?: 'small' | 'medium';
	icon: IconTypes;
};

export const CarouselControl: FC<CarouselControlProps> = ({ size = 'medium', icon, ...props }) => {
	const { classes, cx } = useStyles({ size });

	return (
		<MUIButton {...props} className={cx(classes.container, classes[size], props.className)}>
			<Icon type={icon} size={size} />
		</MUIButton>
	);
};
