import { FC, ReactNode } from 'react';

import { GlobalStyles } from 'tss-react';

import { useCustomStyles } from './Content.style';

export type ContentProps = {
	children: ReactNode;
	className?: string;
	variant?: 'space-between' | 'full-width';
	flexDirection?: 'row' | 'column';
	/** Makes the content full height */
	fullHeight?: boolean;
};

export const Content: FC<ContentProps> = ({ children, className, variant, flexDirection, fullHeight }) => {
	const { classes, cx } = useCustomStyles(flexDirection);
	const variantClass =
		(variant === 'space-between' && classes.withSpacing) || (variant === 'full-width' && classes.fullWidth);

	return (
		<>
			{fullHeight && (
				<GlobalStyles
					// eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
					styles={{
						main: {
							display: 'flex',
							flexFlow: 'column',
							height: '100vh',
						},
						['#__next']: {
							height: '100vh',
						},
					}}
				/>
			)}
			<div
				className={cx(
					classes.generalContainer,
					variantClass || classes.container,
					flexDirection && classes.flexContainer,
					fullHeight && classes.fullHeight,
					className,
				)}
			>
				{children}
			</div>
		</>
	);
};
