import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles()((theme) => ({
	container: {
		backgroundColor: theme.caution.color.default,
		textAlign: 'center',
		padding: theme.spacing.xxsmall,

		a: {
			'&:hover': {
				color: theme.caution.color.pressed,
			},
		},
	},
}));
