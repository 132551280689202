import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

import { CarouselControlProps } from './CarouselControl';

export const useStyles = makeStyles<Pick<CarouselControlProps, 'size'>>()((theme, { size = 'medium' }) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
		cursor: 'pointer',
		boxSizing: 'border-box',
		border: 'none',
		gap: theme.spacing.xxsmall,
		aspectRatio: '1',
		borderRadius: theme.borderRadius.circle,
		backgroundColor: theme.button.carouselControl.background.color.default,
		color: theme.button.carouselControl.foreground.default,

		['@media (hover: hover)']: {
			'&:hover': {
				backgroundColor: theme.button.carouselControl.background.color.hover,
				color: theme.button.carouselControl.foreground.hover,
			},
		},

		'&:focus-visible': {
			backgroundColor: theme.button.carouselControl.background.color.focus,
			color: theme.button.carouselControl.foreground.focus,
			boxShadow: theme.button.focus,
		},
		'&:active': {
			backgroundColor: theme.button.carouselControl.background.color.pressed,
			color: theme.button.carouselControl.foreground.pressed,
		},
		'&:disabled': {
			cursor: 'unset',
			backgroundColor: theme.button.carouselControl.background.color.disabled,
			color: theme.button.carouselControl.foreground.disabled,
		},
	},
	icon: {
		width: theme.icon.size[size],
		height: theme.icon.size[size],
	},
	medium: {
		width: theme.carouselControl.size.md,
		height: theme.carouselControl.size.md,
		padding: theme.spacing.xxsmall,
	},
	small: {
		width: theme.carouselControl.size.sm,
		height: theme.carouselControl.size.sm,
		padding: theme.spacing.tiny,
	},
}));
