import { FC } from 'react';

import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { Typography } from '@vakantiesnl/components/src/atoms/Typography';

import { useStyles } from './HeadingWithTick.style';

type HeadingWithTickProps = {
	title: string;
};

export const HeadingWithTick: FC<HeadingWithTickProps> = ({ title }) => {
	const { classes } = useStyles();

	return (
		<Typography as="h4" variant="heading2Xs" className={classes.heading}>
			<Icon type="checkmark" size="small" /> {title}
		</Typography>
	);
};
